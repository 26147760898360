import axios from 'axios';
//import router from '../router';

const state = {
	menu: [],
	fields: [],
	status: '',
	errors: '',
	image: ''
};

const getters = {
	menu: state => state.menu,
	image: state => state.image,
	fields: state => state.fields,
	errors: state => state.errors
};

const actions = {

	async getImageByID({
		commit
	}, image_id) {
		try {
			commit('general_image_request');
			let res = await axios.get(process.env.VUE_APP_API_URL + '/wp/v2/media/' + image_id)

			commit('general_image_success', res.data.media_details.sizes.full.source_url);
			
			return res;
		} catch (err) {
			commit('general_image_error', err)
		}
	},

	async getMenu({
		commit
	}, name_menu) {
		try {
			commit('general_menu_request');
			let res = await axios.post(process.env.VUE_APP_API_URL + '/wl/v1/menu/get?lang=' + localStorage.getItem('lang'), name_menu)
			if(res.data.success !== undefined){
				commit('general_menu_success', res.data);
			}
			return res;
		} catch (err) {
			commit('general_menu_error', err)
		}
	},

	async getACF({commit}, page_id) {
		commit('page_fields_request');
		let res = await axios.get(process.env.VUE_APP_API_URL + '/wl/v1/acf/getfieldbyid/' + page_id)
		commit('page_fields_success', res.data);
		return res;
	},

	async getFeaturedImage({commit}, page_id) {
		commit('general_page_feat_request');
		let res = await axios.get(process.env.VUE_APP_API_URL + '/wp/v2/pages/' + page_id)
		let feat = await axios.get(res.data._links['wp:featuredmedia'][0].href)
		commit('general_page_feat_success', feat.data.media_details.sizes);
		return feat.data.media_details.sizes;
	},
	
};

const mutations = {
	general_image_request(state){
		state.status = 'loading'
	},
	general_image_success(state, imageUrl){
		state.image = imageUrl
		state.status = 'success'
	},
	general_image_error(state, err){
		state.error = err.response.data.msg
	},
	general_menu_request(state){
		state.status = 'loading'
	},
	general_menu_success(state, menu_obj){
		state.menu = menu_obj
		state.status = 'success'
	},
	general_menu_error(state, err){
		state.error = err.response.data.msg
	},
	page_fields_request(state){
		state.status = 'loading'
	},
	page_fields_success(state, flds){
		state.fields = flds
		state.status = 'success'
	},
	general_page_feat_request(state){
		state.status = 'loading'
	},
	general_page_feat_success(state, images){
		state.fields = images
		state.status = 'success'
	},
};

export default {
	state,
	actions,
	getters,
	mutations
}