<template>
  <div class="header">
    <div class="header--up">
      <div class="header--logo">
        <router-link :to="$i18nRoute({ name: 'Home' })" class="link--mouse-small">
					<LogoSVG />
          <span>{{$t('header.back_home')}}</span>
        </router-link>
      </div>
      <div class="header--menu-icon link--mouse-small" @click.prevent="toggleMenu" >
        <span></span>
        <span class="label">Menu</span>
        <span></span> 
      </div>
      <div class="header--visible-menu">
				<div class="logout--user" @click.prevent="logoutUser" v-if="$currentUserID !== null" @mouseenter="changeMouse('on')" @mouseleave="changeMouse('off')">
					<span>{{$t('header.logout')}}</span>
					<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 490.3 490.3" style="enable-background:new 0 0 490.3 490.3;" xml:space="preserve">
						<path d="M0,121.05v248.2c0,34.2,27.9,62.1,62.1,62.1h200.6c34.2,0,62.1-27.9,62.1-62.1v-40.2c0-6.8-5.5-12.3-12.3-12.3
							s-12.3,5.5-12.3,12.3v40.2c0,20.7-16.9,37.6-37.6,37.6H62.1c-20.7,0-37.6-16.9-37.6-37.6v-248.2c0-20.7,16.9-37.6,37.6-37.6h200.6
							c20.7,0,37.6,16.9,37.6,37.6v40.2c0,6.8,5.5,12.3,12.3,12.3s12.3-5.5,12.3-12.3v-40.2c0-34.2-27.9-62.1-62.1-62.1H62.1
							C27.9,58.95,0,86.75,0,121.05z"/>
						<path d="M385.4,337.65c2.4,2.4,5.5,3.6,8.7,3.6s6.3-1.2,8.7-3.6l83.9-83.9c4.8-4.8,4.8-12.5,0-17.3l-83.9-83.9
							c-4.8-4.8-12.5-4.8-17.3,0s-4.8,12.5,0,17.3l63,63H218.6c-6.8,0-12.3,5.5-12.3,12.3c0,6.8,5.5,12.3,12.3,12.3h229.8l-63,63
							C380.6,325.15,380.6,332.95,385.4,337.65z"/>
					</svg>
				</div>
        <LanguageSwitcher />
        <router-link :to="'/' + $i18n.locale + '/' + $t('header.products_link')" class="link--mouse-small prd_cat">{{$t('header.product_catalogue')}}</router-link>
      </div>
    </div>
    <Menu :open="menuOpen" @opening="checkAnimation" @resetMenu="resetMenu" />
  </div>
</template>

<script>
import Menu from './Menu'
import LogoSVG from '@/components/elements/LogoSVG';
import LanguageSwitcher from '@/components/LanguageSwitcher';
import { mapActions } from 'vuex';
export default {
  data() {
    return {
      isOpening: false,
			menuOpen: false
    }
  },
  components: {
    Menu,
		LogoSVG,
		LanguageSwitcher
  },
  methods: {
		...mapActions(['logout']),
		logoutUser() {
			this.logout().then(() => {
				this.$currentUserID = null
				this.$router.push('/' + this.$i18n.locale + '/login')
			})
			//this.$router.go()
		},
		resetMenu() {
			this.menuOpen = false
			this.isMenuOpened = false
		},
    toggleMenu(){
      if(!this.isOpening){
        if(this.menuOpen){
          this.menuOpen = false
          this.isMenuOpened = false
        }else{
          this.menuOpen = true
          this.isMenuOpened = true
        }
      }
    },
    checkAnimation (value) {
      if(value){
        this.isOpening = true
      }else{
        this.isOpening = false
      }
		}
  }
}
</script>

<style lang="scss" scoped>
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
	z-index: 199;
	background-color: $white;
  .header--up {
    position: relative;
    height: 80px;
    display: grid;
		align-items: center;
		grid-template-columns: calc(100%/3) calc(100%/3) calc(100%/3);
		grid-template-rows: auto;
		z-index: 99;
    .header--logo {
      position: relative;
      width: auto;
      margin-left: 1vw;
			@include mobile {
				margin-left: 5vw;
				height: 25px;
			}
      a {
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: nowrap;
        text-decoration: none;
        height: 40px;
				@include mobile {
					height: 25px;
				}
        svg {
          height: 30px;
          width: auto;
          fill: $red;
					stroke: $black;
					stroke-width: 1px;
					@include mobile {
						height: 25px;
					}
        }
        span {
          font-family: 'Suisse Int';
          font-weight: 500;
          color: $black;
          font-size: 12px;
          text-transform: uppercase;
					@include mobile {
						display: none;
					}
        }
      }
    }
    .header--menu-icon {
      position: relative;
      width: auto;
      height: auto;
      cursor: pointer;
      justify-self: center;
      span {
        &:not(.label){
          position: absolute;
          width: 100%;
          height: 1px;
          left: 0;
          background-color: $black;
          &:first-of-type {
            top: 0;
          }
          &:last-of-type {
            bottom: 0;
          }
        }
        &.label {
          font-family: 'Suisse Int';
          font-weight: 500;
          color: $black;
          font-size: 12px;
          text-transform: uppercase;
          line-height: 14px;
        }
      }
    }
    .header--visible-menu {
      position: relative;
      width: auto;
      height: auto;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-right: 1vw;
      a {
        font-family: 'Suisse Int';
        font-weight: 500;
        color: $black;
        font-size: 12px;
        text-transform: uppercase;
        line-height: 14px;
      }
			.logout--user {
				position: relative;
				margin: 0;
				padding: 0;
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-right: 5vw;
				span {
					font-family: 'Suisse Int';
					font-weight: 500;
					color: $black;
					font-size: 12px;
					text-transform: uppercase;
					line-height: 14px;
				}
				svg {
					fill: $black;
					height: 14px;
					width: auto;
					margin-left: 10px;
				}
				@include mobile {
					span {
						display: none;
					}
				}
			}
			@include mobile {
				margin-right: 5vw;
				.prd_cat {
					display: none;
				}
			}
    }
		@include mobile {
			height: 60px;
		}
  }
}
</style>
