<template>
  <div class="header--main-menu" :class="{'open' : open}">
		<div class="main--menu-curtain-1"></div>
		<div class="main--menu-curtain-2"></div>
		<div class="main--menu-list">
			<div class="list--container" v-if="menuItems">
				<div class="list--item" v-for="(item, index) in menuItems" :key="index">
					<router-link @click.native="checkMenu" :to="$i18nRoute({ name: item.attributo_nome })" v-html="splitWord(item.nome)"></router-link>
					<div class="line"></div>
				</div>
			</div>
		</div>
  </div>
</template>

<script>
//:to="$i18nRoute({ name: item.attributo_nome })" il :to del router-link
import { gsap } from 'gsap';
import axios from 'axios';
export default {
  data() {
    return {
      menuItems: []
    }
  },
  props: {
    open: {
      type: Boolean,
      required: true
    }
  },
  watch: {
    open: {
      deep: true,
      handler: function(val){
        this.$emit('opening', true)
        if(val){					
          this.openMenu();
        }else{
					this.closeMenu();
        }
      }
    },
		'$route.params.locale': function(){
			this.getMenu()
		}
  },
	methods: {
		getMenu() {
			let menu_id = parseInt(this.$t('header.menu'));
			axios
			.get(process.env.VUE_APP_API_URL + '/wl/v1/acf/getfieldbyid/' + menu_id)
			.then(response => {
				this.menuItems = response.data.voci_di_menu
			});
		},
		splitWord(word){
			let str = '';
			let spl = word.split('');
			spl.forEach(char => {
				if(char == ' '){
					str += '<span>&nbsp;</span>';
				}else{
					str += '<span>' + char + '</span>';
				}
			})
			return str;
		},
		openMenu(){
			gsap.to('.header--main-menu', {duration: 0, height: '100vh'});
			gsap.to('.language-switcher', {duration: .5, autoAlpha: 0, ease: 'power1.out'});
			gsap.to('.main--menu-curtain-1', {duration: .5, height: '100%', ease: 'power1.out'});
			gsap.to('.main--menu-curtain-2', {duration: .5, delay: .25, height: '100%', ease: 'power1.out', onComplete: () => {
				gsap.to(document.querySelectorAll('.list--item'), {duration: .5, x: 0, stagger: {each: 0.1, ease: 'power2.out', onStart: function(){
					let curr = this.targets()[0];
					gsap.to(curr.querySelectorAll('a span'), {duration: .2, stagger: 0.03, autoAlpha: 1, ease: 'power2.out'})
					gsap.to(curr.querySelector('.line'), {duration: .5, webkitClipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)', clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)', onComplete: () => {
						gsap.to(curr.querySelector('.line'), {duration: .5, webkitClipPath: 'polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%)', clipPath: 'polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%)', ease: 'power2.out'})
					}, ease: 'power2.in'})
				}}, onComplete: () => {
					document.querySelector('.main--menu-curtain-1').style.bottom = 'auto';
					document.querySelector('.main--menu-curtain-1').style.top = '0';
					document.querySelector('.main--menu-curtain-2').style.bottom = 'auto';
					document.querySelector('.main--menu-curtain-2').style.top = '0';
					this.$emit('opening', false);
					this.$isMenuOpened = true;
				}})
			}});
		},
		closeMenu(animationEnabled = true){
			if(animationEnabled){
				gsap.to(document.querySelectorAll('.list--item'), {duration: .5, x: '-10vw', stagger: {each: -0.1, ease: 'power2.in', onStart: function(){
					let curr = this.targets()[0];
					gsap.to(curr.querySelectorAll('a span'), {duration: .2, stagger: -0.03, autoAlpha: 0, ease: 'power2.out'})
					gsap.to(curr.querySelector('.line'), {duration: .25, webkitClipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)', clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)', onComplete: () => {
						gsap.to(curr.querySelector('.line'), {duration: .25, webkitClipPath: 'polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)', clipPath: 'polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)', ease: 'power2.out'})
					}, ease: 'power2.out'})
				}}, onComplete: () => {
					gsap.to('.main--menu-curtain-2', {duration: .5, height: '0%', ease: 'power1.out'})
					gsap.to('.main--menu-curtain-1', {duration: .5, delay: .25, height: '0%', ease: 'power1.out', onComplete: () => {
						document.querySelector('.main--menu-curtain-1').style.top = 'auto';
						document.querySelector('.main--menu-curtain-1').style.bottom = '0';
						document.querySelector('.main--menu-curtain-2').style.top = 'auto';
						document.querySelector('.main--menu-curtain-2').style.bottom = '0';
						this.$emit('opening', false);
						this.$emit('resetMenu', true);
						this.$isMenuOpened = false;
						gsap.to('.header--main-menu', {duration: 0, height: '0'});
						gsap.to('.language-switcher', {duration: .5, autoAlpha: 1, ease: 'power1.out'});
					}});
				}})
			}else{
				gsap.to(document.querySelectorAll('.list--item'), {duration: 0, delay: .5, x: '-10vw', stagger: {each: 0, onStart: function(){
					let curr = this.targets()[0];
					gsap.to(curr.querySelectorAll('a span'), {duration: 0, stagger: 0, autoAlpha: 0})
					gsap.to(curr.querySelector('.line'), {duration: 0, webkitClipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)', clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)', onComplete: () => {
						gsap.to(curr.querySelector('.line'), {duration: 0, webkitClipPath: 'polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)', clipPath: 'polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)'})
					}})
				}}, onComplete: () => {
					gsap.to('.main--menu-curtain-2', {duration: 0, height: '0%', ease: 'power1.out'})
					gsap.to('.main--menu-curtain-1', {duration:  0, delay: 0, height: '0%', onComplete: () => {
						document.querySelector('.main--menu-curtain-1').style.top = 'auto';
						document.querySelector('.main--menu-curtain-1').style.bottom = '0';
						document.querySelector('.main--menu-curtain-2').style.top = 'auto';
						document.querySelector('.main--menu-curtain-2').style.bottom = '0';
						this.$emit('opening', false);
						this.$emit('resetMenu', true);
						this.$isMenuOpened = false;
						gsap.to('.header--main-menu', {duration: 0, height: '0'});
						gsap.to('.language-switcher', {duration: 0, autoAlpha: 1});
					}});
				}})
			}
		},
		checkMenu() {
			this.closeMenu(false)
		}
	},
	beforeMount() {
		this.getMenu()
	}
}
</script>

<style lang="scss">
.header--main-menu {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 0;
	z-index: 95;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	.main--menu-curtain-1 {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 0;
		background-color: $red;
		z-index: 90;
	}
	.main--menu-curtain-2 {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 0;
		background-color: $white;
		z-index: 91;
	}
	.main--menu-list {
		position: relative;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		z-index: 92;
		.list--container {
			position: relative;
			display: flex;
			align-items: center;
			flex-direction: column;
			.list--item {
				position: relative;
				transform: translateX(-10vw);
				a {
					position: relative;
					display: flex;
					flex-wrap: nowrap;
					span {
						font-family: 'Suisse Int';
						font-weight: 400;
						color: $black;
						text-transform: uppercase;
						font-size: 2.5vw;
						line-height: 3.5vw;
						-webkit-text-stroke: 1px $black;
						-webkit-text-fill-color: transparent;
						opacity: 0;
						@include mobile {
							font-size: 7.5vw;
							line-height: 10vw;
							-webkit-text-fill-color: $black;
						}
					}
					&.router-link-exact-active {
						span {
							-webkit-text-stroke: 1px $red;
							-webkit-text-fill-color: $red;
						}
					}
				}
				.line {
					position: absolute;
					bottom: 0;
					width: 100%;
					height: 2px;
					background-color: $red;
					clip-path: polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%);
					@include mobile {
						display: none;
					}
				}
			}
		}
	}
}
</style>