<template>
  <div id="app" @mousemove="updateCursor">
		<div id="cursor" ></div>
		<div class="loader">
			<Loader :active="loaderActive" @resetting="resetLoader"/>
		</div>
		<FormLoader :isVisible="$formLoaderVisible"/>
    <Header />
    <div class="foot-on-bottom"><router-view id="container" class="container" :class="addClass ? 'goOn' : ''" /></div>
		<Footer :isFixed="isFooterFixed"/>
  </div>
</template>

<script>
import Header from './components/Header'
import Footer from './components/Footer'
import Loader from './components/Loader'
import FormLoader from '@/components/FormLoader'
import { gsap } from 'gsap'
//import axios from "axios";
export default {
	data() {
		return {
			mousePosX: 0,
			mousePosY: 0,
			isFooterFixed: false,
			loading: false,
			loaderActive: false,
			hasClass: false
		}
	},
	metaInfo() {
		return {
			title: 'Wearlight',
			titleTemplate: '%s',
			meta: [
				{ charset: 'utf-8' },
				{ vmid: "description", name: 'description', content: 'Lavorazione Artigianale Fondi per Calzature Montegranaro Italia' },
				{ vmid: "viewport", name: 'viewport', content: 'user-scalable=no, width=device-width, initial-scale=1, maximum-scale=1' }
			]
		}
  },
  components: {
    Header,
		Footer,
		Loader,
		FormLoader
  },
  methods: {
		addClass(){
			setTimeout(() => {
				return true
			}, 500)
		},
		resetLoader(){
			this.loaderActive = false
		},
    updateCursor: function(event) {
			let cursor = document.getElementById('cursor')
      this.mousePosX = event.clientX;
      this.mousePosY = event.clientY;

			gsap.to(cursor, {x: this.mousePosX, y: this.mousePosY, duration: 0.5, ease: 'power2.out'})
    },
		checkCurrentPage(){
			if(this.$route.name == "Home" ) {
				this.isFooterFixed = true
			} else {
				this.isFooterFixed = false
			}
		}
  },
	watch: {
		'$route.name': function(){
			this.checkCurrentPage()
		}
	},
	mounted() {
		let linkSmall = document.querySelectorAll('.link--mouse-small');
		[...linkSmall].forEach(el => {
			el.addEventListener('mouseenter', () => {
				let cursor = document.getElementById('cursor')
				cursor.classList.add('onLink--small')
			})
			el.addEventListener('mouseleave', () => {
				let cursor = document.getElementById('cursor')
				cursor.classList.remove('onLink--small')
			})
		});
	},
	beforeMount() {
		this.checkCurrentPage()
	},
	created: function() {
    this.$router.beforeEach((to, from, next) => {
			this.loading = true
			this.loaderActive = true
			gsap.to('.container', {duration: .5, autoAlpha: 0, ease: 'power2.out', onComplete: () =>{
				//gsap.to('.container', {duration: 0, y: });
				next()
			}});
    });
    this.$router.afterEach(() => {
			window.scrollTo(0,0);
    });
  }
}
</script>

<style lang="scss">
#app {
	position: relative;
  width: 100%;
  min-height: calc(100vh - 80px);
	background-color: $white;
	overflow: hidden;
}
.foot-on-bottom {
	position: relative;
	width: 100%;
	min-height: calc(100vh - 80px);
}
.container {
	overflow: hidden;
  min-height: calc(100vh - 80px);
	opacity: 0;
	//transform: translateY(10vh);
	&.goOn {
		opacity: 1;
		//transform: translateY(0vh);
		transition: opacity .35s ease-in-out 0s;
	}
}
.loader {
	position: relative;
	z-index: 999;
}
#cursor {
	position: fixed;
	top: 0;
	left: 0;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background-color: $white;
	mix-blend-mode: difference;
	z-index: 999;
	transition: width .35s ease-in-out 0s, height .35s ease-in-out 0s;
	backface-visibility: hidden;
	user-select: none;
	pointer-events: none;
	transform: translate(-50%, -50%);
	&.onLink--small {
		width: 40px;
		height: 40px;
		transition: width .35s ease-in-out 0s, height .35s ease-in-out 0s;
	}
	&.onLink--big {
		width: 9vw;
		height: 9vw;
		transition: width .35s ease-in-out 0s, height .35s ease-in-out 0s;
	}
	@include mobile {
		display: none;
	}
}
</style>
