<template>
	<ul class="language-switcher">
		<li :class="(locale==$i18n.locale) ? 'current--language' : ''" v-for="locale in locales" :key="locale" @click="switchLocale(locale)" @mouseenter="changeMouse('on')" @mouseleave="changeMouse('off')">{{locale}}</li>
	</ul>
</template>

<script>
import { Trans } from '@/plugins/Translation'
export default {
	name: 'LanguageSwitcher',
	data() {
		return {
			locales: process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(',')
		}
	},
	computed: {
    supportedLocales () {
      return Trans.supportedLocales
    },
  },
	methods: {
    switchLocale(locale) {
      if (this.$i18n.locale !== locale) {
        const to = this.$router.resolve({ params: {locale} })
        return Trans.changeLocale(locale).then(() => {
          this.$router.push(to.location)
					//this.$router.go()
        })
      }
    }
  },
}
</script>

<style lang="scss">
.language-switcher {
	list-style: none;
	margin: 0;
	padding: 0;
	display: flex;
	margin-right: 5vw;
	li {
		width: 34px;
		font-family: 'Suisse Int';
		font-weight: 500;
		color: $black;
		font-size: 12px;
		text-transform: uppercase;
		line-height: 14px;
		padding: 10px 0;
		transition: color .35s ease-in-out 0s, background-color .35s ease-in-out 0s;
		border-radius: 50%;
		text-align: center;
		&.current--language {
			background-color: $black;
			color: $white;
			transition: color .35s ease-in-out 0s, background-color .35s ease-in-out 0s;
		}
	}
	@include mobile {
		margin: 0;
		li {
			width: 25px;
			border-radius: 0;
			&.current--language {
				background-color: transparent;
				color: $red;
			}
		}
	}
}
</style>