<template>
	<div class="loader--curtains">
		<div class="curtain--red"></div>
		<div class="curtain--white"></div>
	</div>
</template>

<script>
import { gsap } from 'gsap';
export default {
	props: {
		active: {
			type: Boolean
		}
	},
	watch: {
		active: {
			deep: true,
      handler: function(val){
        //this.$emit('opening', true)
        if(val == 1){					
          this.loaderUp();
        }else if(val == 2){
					this.loaderDown();
        }
      }
		}
	},
	methods: {
		loaderUp() {
			gsap.to('.loader--curtains', {duration: 0, height: '100vh'});
			gsap.to('.curtain--red', {duration: .5, height: '100%', ease: 'power2.out', onComplete: () => {
				document.querySelector('.curtain--red').style.bottom = 'auto';
				document.querySelector('.curtain--red').style.top = '0';
				gsap.to('.curtain--red', {duration: .5, delay: .1, height: '0%', ease: 'power2.out', onComplete: () => {
					document.querySelector('.curtain--red').style.top = 'auto';
					document.querySelector('.curtain--red').style.bottom = '0';
					gsap.to('.loader--curtains', {duration: 0, height: '0vh'});
					//gsap.to('.container', {duration: .5, autoAlpha: 1, y: 0, ease: 'power2.out'});
					gsap.to('.container', {duration: .5, autoAlpha: 1, ease: 'power2.out'});
					this.$emit('resetting', true);
				}});
			}});
		}
	}
}
</script>

<style lang="scss">
.loader--curtains {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 0vh;
	overflow: hidden;
	.curtain--red {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 0;
		background-color: $red;
		z-index: 90;
	}
	.curtain--white {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 0;
		background-color: $white;
		z-index: 91;
	}
}
</style>