import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import store from '../store'
import routes from './routes'
import RouterPrefetch from 'vue-router-prefetch'
import { Trans } from '../plugins/Translation'

Vue.use(VueRouter)
Vue.use(RouterPrefetch)
Vue.use(VueMeta)

/* metaTags: [ //Add on meta
	{
		name: 'description',
		content: 'The home page of our example app.'
	},
	{
		property: 'og:description',
		content: 'The home page of our example app.'
	}
], */



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
	/* const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  if(nearestWithTitle) document.title = nearestWithTitle.meta.title;
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));
  if(!nearestWithMeta) return next();
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  .forEach(tag => document.head.appendChild(tag)); */

	if(to.matched.some(record => record.meta.requiresAuth)){
		if(!store.getters.isLoggedIn){
			if(localStorage.getItem('lang') == 'it'){
				next('/' + Trans.currentLocale + '/login');
			}else{
				next('/' + Trans.currentLocale + '/login');
			}
		}else{
			next();
		}
	}else if(to.matched.some(record => record.meta.requiresGuest)){
		if(store.getters.isLoggedIn){
			if(localStorage.getItem('lang') == 'it'){
				next('/' +  Trans.currentLocale+ '/prodotti');
			}else{
				next('/' + Trans.currentLocale + '/products');
			}			
		}else{
			next();
		}
	}else{
		next();
	}
})

export default router
