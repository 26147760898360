//import i18n from '../i18n'
import { Trans } from '@/plugins/Translation'

/**
 * Ricordarsi di aggiungere il campo 'Attributo del titolo' nel menu di wordpress. Deve corrispondere al name
 */

const routes = [
  {
    path: '/:locale',
    component: {
      template: "<router-view></router-view>"
    },
		beforeEnter: Trans.routeMiddleware,
		children: [
			{
				path: '',
				name: 'Home',
				meta: {
					title: 'WearLight',
				},
				component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
			},
			{
				path: 'about',
				name: 'About',
				meta: {
					title: 'About Us - WearLight',
				}, 
				component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
			},
			{
				path: 'technology',
				name: 'Technology',
				meta: {
					title: 'Tecnologia - WearLight',
				},
				component: () => import(/* webpackChunkName: "technology" */ '../views/Technology.vue')
			},
			{
				path: 'applications',
				name: 'Applications',
				meta: {
					title: 'Applicazioni - WearLight',
				},
				component: () => import(/* webpackChunkName: "applications" */ '../views/Applications.vue')
			},
			{
				path: 'application/:slug',
				name: 'Application',
				meta: {
					title: 'Applicazione - WearLight',
				},
				component: () => import(/* webpackChunkName: "single-applicazione" */ '../views/Application.vue')
			},
			{
				path: 'patents',
				name: 'Patents',
				meta: {
					title: 'Brevetti - WearLight',
				},
				component: () => import(/* webpackChunkName: "pantents" */ '../views/Patents.vue')
			},
			{
				path: 'patent/:slug',
				name: 'Patent',
				meta: {
					title: 'Brevetti - WearLight',
				},
				component: () => import(/* webpackChunkName: "single-pantent" */ '../views/Patent.vue')
			},
			{
				path: 'sustainability',
				name: 'Sustainability',
				meta: {
					title: 'Sostenibilità - WearLight',
				},
				component: () => import(/* webpackChunkName: "sustainability" */ '../views/Sustainability.vue')
			},
			{
				path: 'news',
				name: 'News',
				meta: {
					title: 'News - WearLight',
				},
				component: () => import(/* webpackChunkName: "applicazioni" */ '../views/News.vue'),
				children: [
					{
						path: ':page',
						component: () => import(/* webpackChunkName: "prodotti" */ '../views/News.vue'),
					}
				]
			},
			{
				path: 'post/:slug',
				name: 'Post',
				meta: {
					title: 'Articolo - WearLight',
				},
				component: () => import(/* webpackChunkName: "single-prodotto" */ '../views/Post.vue')
			},
			{
				path: 'contacts',
				name: 'Contacts',
				meta: {
					title: 'Contatti - WearLight',
				},
				component: () => import(/* webpackChunkName: "applicazioni" */ '../views/Contacts.vue')
			},
			{ 
				path: 'products',
				name: 'Products',
				meta: {
					requiresAuth: true,
					title: 'Prodotti - WearLight',
				},
				component: () => import(/* webpackChunkName: "prodotti" */ '../views/Products.vue'),
				children: [
					{
						path: ':page',
						component: () => import(/* webpackChunkName: "prodotti" */ '../views/Products.vue'),
					}
				]
			},
			{
				path: 'product/:slug',
				name: 'Product',
				meta: {
					requiresAuth: true,
					title: 'Prodotto - WearLight',
				},
				component: () => import(/* webpackChunkName: "single-prodotto" */ '../views/Product.vue')
			},
			{
				path: 'carrello',
				name: 'Cart',
				alias: 'cart',
				meta: {
					requiresAuth: true,
					title: 'Carrello - WearLight',
				},
				component: () => import(/* webpackChunkName: "cart" */ '../views/Cart.vue')
			},
			{
				path: 'login',
				name: 'Login',
				meta: {
					requiresGuest: true,
					title: 'Login - WearLight',
				},
				component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
			},
			{
				path: 'signup',
				name: 'Signup',
				meta: {
					requiresGuest: true,
					title: 'Registrazione - WearLight',
				},
				component: () => import(/* webpackChunkName: "register" */ '../views/Register.vue')
			},
			{
				path: 'privacy',
				name: 'Privacy',
				meta: {
					title: 'Privacy Policy - WearLight',
				},
				component: () => import(/* webpackChunkName: "privacy" */ '../views/Privacy.vue')
			}
		]
  },
  {
    path: '*',
    redirect() {
			localStorage.setItem('lang', Trans.defaultLocale)
      return Trans.defaultLocale;
    }
  }
]

export default routes