import Vue from 'vue'
import VueMeta from 'vue-meta'
import Vuex from 'vuex'
import App from './App.vue'
import router from './router'
import store from './store'
import jwt_decode from "jwt-decode";
import i18n from './i18n'
import { Trans } from './plugins/Translation'
import axios from 'axios';
import VueAnalytics from 'vue-analytics';

Vue.prototype.$i18nRoute = Trans.i18nRoute.bind(Trans)

Vue.config.productionTip = false

Vue.use(VueMeta, {
  keyName: 'head',
	refreshOnceOnNavigation: true
})

Vue.use(VueAnalytics, {
	id: 'UA-105971103-43',
	router
})

if (localStorage.getItem("lang") === null) {
	localStorage.setItem('lang', process.env.VUE_APP_I18N_LOCALE)
}

/**
 * Remove Login token on browser/tab close
 */
Vue.mixin({
	created() {
		window.addEventListener('beforeunload', this.handler, {capture: true})
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.handler, {capture: true})
  },
	methods: {
		handler() {
			if(localStorage.getItem('token') && localStorage.getItem('token') !== undefined){
				let role = jwt_decode(localStorage.getItem('token')).role;
				let expires = jwt_decode(localStorage.getItem('token')).exp;
				if(role == 'b2b_customer'){
					if(expires < Math.floor(Date.now() / 1000)){
						localStorage.removeItem('token');
						delete axios.defaults.headers.common['Authorization'];
					}
				}
			}
		}
	}
});

const menuVariation = new Vuex.Store({
  state: { 
		$isMenuOpened: false,
		$formLoaderVisible: false,
		$formLoaderAnimation: true,
		$currentUserID: localStorage.getItem('token') ? jwt_decode(localStorage.getItem('token')).ID : null
	},
  mutations: { 
		update$isMenuOpened: function(state, status) { 
			state.$isMenuOpened = status; 
		},
		update$formLoaderVisible: function(state, status) { 
			state.$formLoaderVisible = status; 
		},
		update$formLoaderAnimation: function(state, status) { 
			state.$formLoaderAnimation = status; 
		},
		update$currentUserID: function(state, userID) { 
			state.$currentUserID = userID; 
		}
	}
});

Vue.mixin({
  computed: {
    $isMenuOpened: {
      get: function() { return menuVariation.state.$isMenuOpened },
      set: function(status) { return menuVariation.commit('update$isMenuOpened', status); }
    },
    $formLoaderVisible: {
      get: function() { return menuVariation.state.$formLoaderVisible },
      set: function(status) { return menuVariation.commit('update$formLoaderVisible', status); }
    },
    $formLoaderAnimation: {
      get: function() { return menuVariation.state.$formLoaderAnimation },
      set: function(status) { return menuVariation.commit('update$formLoaderAnimation', status); }
    },
    $currentUserID: {
      get: function() { return menuVariation.state.$currentUserID },
      set: function(userID) { return menuVariation.commit('update$currentUserID', userID); }
    }		
  }
})

Vue.mixin({
	data() {
		return {
			api_url:'https://www.wearlight.com/api/wp-json',
			isUserLoggedIn: (localStorage.getItem('token')) ? true : false,
			currentUserID: (localStorage.getItem('token')) ? jwt_decode(localStorage.getItem('token')).ID : null
		}
	},
	methods: {
		changeMouse(status) {
			if(status == 'on'){
				let cursor = document.getElementById('cursor')
				cursor.classList.add('onLink--small')
			}else{
				let cursor = document.getElementById('cursor')
					cursor.classList.remove('onLink--small')
			}
		}
	}
})

new Vue({
	i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
