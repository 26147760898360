import axios from 'axios';
//import router from '../router';

const state = {
    token: localStorage.getItem('token') || '',
    user: {},
    status: '',
    errorAuth: null
};

const getters = {
    isLoggedIn: state => !!state.token, //uguale a sopra
    authState: state => state.status,
    user: state => state.user,
    errorAuth: state => state.errorAuth
};

const actions = {
    // Login Action
    async login({ 
			commit 
    }, user) {
			commit('auth_request');
			try {
				let res = await axios.post(process.env.VUE_APP_API_URL + '/wl/v1/user/login', user)
				if(res.data.success){
					const token = res.data.token;
					const user = res.data.user;
					// Store the token into the localstorage
					localStorage.setItem('token', token);
					// Set the axios defaults
					axios.defaults.headers.common['Authorization'] = token;
					commit('auth_success', token, user);
				}
				return res;
			} catch(err) {
				console.log(err)
				commit('auth_error', err);
			}
    },
    // Register User
    async register({
        commit
    }, userData){
        try {
            commit('register_request');
            let res = await axios.post(process.env.VUE_APP_API_URL + '/wl/v1/user/register', userData);
            if(res.data.success){
                commit('register_success');
            }
            return res;
        } catch (err) {
            commit('register_error', err);
        }
    },
		async getUserData({commit}, userID) {
			commit('user_data_request');
			let res = await axios.get(process.env.VUE_APP_API_URL + '/wl/v1/user/getData/' + userID)
			commit('user_data_success', res.data);
			return res;
		},
    // Get the user profile
    async getProfile({
        commit
    }){
        commit('profile_request');
        let res = await axios.get('/api/users/profile');
        commit('user_profile', res.data.user)
        return res;
    },
    // Logout the user
    async logout({ commit }){
        await localStorage.removeItem('token');
        commit('logout');
        delete axios.defaults.headers.common['Authorization'];
        //router.push('/login');
        return;
    }
};

const mutations = {
		user_data_request(state){
			state.status = 'loading'
		},
		user_data_success(state, usr){
			state.user = usr
			state.status = 'success'
		},
    auth_request(state) {
        state.errorAuth = null
        state.status = 'loading'
    },
    auth_success(state, token, user){
        state.token = token
        state.user = user
        state.status = 'success'
        state.errorAuth = null
    },
    auth_error(state, err){
        state.errorAuth = err.response.data.msg
    },
    register_request(state){
        state.status = 'loading'
        state.errorAuth = null
    },
    register_success(state){
        state.status = 'success'
        state.errorAuth = null
    },
    register_error(state, err){
        state.errorAuth = err.response.data.msg
    },
    logout(state){
        state.errorAuth = null
        state.status = '',
        state.token = '',
        state.user = ''
    },
    profile_request(state) {
        state.status = 'loading';
    },
    user_profile(state, user) {
        state.user = user;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};