<template>
	<div class="footer" :class="isFixed ? 'fixed' : ''">
		<div class="top-bando">
			<a href="http://www.wearlight.com/api/wp-content/uploads/2022/07/dama_e_co_progetto.pdf" target="_blank">
				<img src="http://www.wearlight.com/api/wp-content/uploads/2022/07/wearlight_bando.jpg">
			</a>
		</div>
		<div class="wrp desktop">
			<div class="left desktop">
				<p>&copy; {{year + ' - ' + r_soc + ' - ' + $t('footer.piva') + ' ' + piva}}</p>
				<a href="http://www.wearlight.com/privacy/privacy_policy.html" target="_blank" class="link--mouse-small" @mouseenter="changeMouse('on')" @mouseleave="changeMouse('off')">Privacy Policy</a>
				<span @click="openCookies" @mouseenter="changeMouse('on')" @mouseleave="changeMouse('off')">Cookie</span>
			</div>
			<div class="right desktop">
				<a href="https://www.awdagency.com/" target="_blank" title="Awd Agency" class="link--mouse-small" @mouseenter="changeMouse('on')" @mouseleave="changeMouse('off')">
					<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 160 100" style="enable-background:new 0 0 160 100;" xml:space="preserve">
						<path class="st0" d="M67.1,23.6C59.6,16.1,50.5,12.3,40,12.3s-19.5,3.8-27.1,11.3C5.4,31.1,1.7,40.2,1.7,50.8S5.4,70.4,13,77.9
								c7.5,7.4,16.5,11.2,27.1,11.2h38.4V50.8C78.4,40.2,74.7,31.1,67.1,23.6 M39.9,70.2c-5.2,0-9.7-1.9-13.4-5.7
								c-3.6-3.8-5.5-8.4-5.5-13.7s1.9-10,5.5-13.8c3.6-3.9,8.1-5.8,13.4-5.8s9.8,1.9,13.4,5.8c3.7,3.8,5.5,8.5,5.5,13.8v0.1H40.2v19.3
								H39.9z"></path>
						<path class="st0" d="M81.3,50.8v38.3h38.4c10.5,0,19.6-3.7,27.1-11.2s11.3-16.5,11.3-27.1s-3.7-19.7-11.3-27.2
								c-7.5-7.5-16.6-11.3-27.1-11.3s-19.5,3.8-27.1,11.3C85,31.1,81.3,40.2,81.3,50.8 M119.5,70.2V50.9h-18.6v-0.1
								c0-5.3,1.9-10,5.5-13.8c3.6-3.9,8.1-5.8,13.4-5.8s9.8,1.9,13.5,5.8c3.6,3.8,5.5,8.5,5.5,13.8s-1.9,9.8-5.6,13.7
								c-3.7,3.8-8.1,5.7-13.4,5.7H119.5z"></path>
					</svg>
				</a>
			</div>
		</div>
		<div class="mobile">
			<div class="top">
				<p>&copy; {{year + ' - ' + r_soc + ' - ' + $t('footer.piva') + ' ' + piva}}</p>
			</div>
			<div class="bottom">
				<a href="http://www.wearlight.com/privacy/privacy_policy.html" target="_blank" class="link--mouse-small prvc" @mouseenter="changeMouse('on')" @mouseleave="changeMouse('off')">Privacy Policy</a>
				<a href="https://www.awdagency.com/" target="_blank" title="Awd Agency" class="link--mouse-small logo" @mouseenter="changeMouse('on')" @mouseleave="changeMouse('off')">
					<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 160 100" style="enable-background:new 0 0 160 100;" xml:space="preserve">
						<path class="st0" d="M67.1,23.6C59.6,16.1,50.5,12.3,40,12.3s-19.5,3.8-27.1,11.3C5.4,31.1,1.7,40.2,1.7,50.8S5.4,70.4,13,77.9
								c7.5,7.4,16.5,11.2,27.1,11.2h38.4V50.8C78.4,40.2,74.7,31.1,67.1,23.6 M39.9,70.2c-5.2,0-9.7-1.9-13.4-5.7
								c-3.6-3.8-5.5-8.4-5.5-13.7s1.9-10,5.5-13.8c3.6-3.9,8.1-5.8,13.4-5.8s9.8,1.9,13.4,5.8c3.7,3.8,5.5,8.5,5.5,13.8v0.1H40.2v19.3
								H39.9z"></path>
						<path class="st0" d="M81.3,50.8v38.3h38.4c10.5,0,19.6-3.7,27.1-11.2s11.3-16.5,11.3-27.1s-3.7-19.7-11.3-27.2
								c-7.5-7.5-16.6-11.3-27.1-11.3s-19.5,3.8-27.1,11.3C85,31.1,81.3,40.2,81.3,50.8 M119.5,70.2V50.9h-18.6v-0.1
								c0-5.3,1.9-10,5.5-13.8c3.6-3.9,8.1-5.8,13.4-5.8s9.8,1.9,13.5,5.8c3.6,3.8,5.5,8.5,5.5,13.8s-1.9,9.8-5.6,13.7
								c-3.7,3.8-8.1,5.7-13.4,5.7H119.5z"></path>
					</svg>
				</a>
			<span @click="openCookies">Cookie</span>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			year: new Date().getFullYear(),
			piva: '01282960440',
			r_soc: 'Da.Ma. & Co. srl'
		}
	},
	props: {
		isFixed: {
			type: Boolean
		}
	},
	methods: {
		openCookies() {

		}
	}
}
</script>

<style lang="scss">
	.footer {
		position: relative;
		width: 100%;
		height: auto;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		padding: 10px 1vw;
		box-sizing: border-box;
		@include mobile {
			flex-direction: column;
		}
		.top-bando {
			position: relative;
			width: 100%;
			display: flex;
			justify-content: center;
			margin-bottom: 20px;
			padding: 20px 0;
			a {
				position: relative;
				img {
					height: 70px;
					width: auto;
					display: block;
					@include mobile {
						height: auto;
						width: 70%;
						margin: 0 15%;
					}
				}
			}
		}
		.wrp {
			position: relative;
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			.left {
				position: relative;
				display: flex;
				p {
					color: $black;
					font-size: 14px;
					font-weight: 400;
					font-family: 'Suisse Int';
					margin: 0;
					line-height: 16px;
					@include mobile {
						font-size: 12px;
						margin-bottom: 10px;
					}
				}
				span {
					color: $black;
					font-size: 14px;
					font-weight: 400;
					font-family: 'Suisse Int';
					margin: 0;
					line-height: 16px;
					text-transform: uppercase;
					margin-left: 10px;
					@include mobile {
						font-size: 12px;
						margin-bottom: 10px;
						margin-left: 0;
					}
				}
				a {
					color: $black;
					font-size: 12px;
					font-weight: 500;
					font-family: 'Suisse Int';
					margin: 0;
					line-height: 16px;
					text-decoration: none;
					text-transform: uppercase;
					margin-left: 10px;
					@include mobile {
						font-size: 12px;
						margin-bottom: 10px;
						margin-left: 0;
					}
				}
				@include mobile {
					flex-direction: column;
					text-align: center;
				}
			}
			.right {
				position: relative;
				a {
					position: relative;
					display: block;
					svg {
						height: 16px;
						fill: $black;
						width: auto;
						display: block;
					}
				}
			}
		}
		&.fixed {
			position: fixed;
			bottom: 0;
			left: 0;
			z-index: 55;
			.top-bando {
				justify-content: flex-start;
				// display: none;
				// viibility: hidden;
				// opacity: 0;
				a {
					img {
						height: 40px;
					}
				}
			}
			@include mobile {
				//display: none;
				position: relative;
				.top-bando {
					justify-content: center;
					a {
						img {
							height: auto;
							width: 70%;
							margin: 0 15%;
						}
					}
				}
			}
		}
		.desktop {
			@include mobile {
				display: none;
			}
		}
		.mobile {
			display: none;
			@include mobile {
				display: flex;
				flex-direction: column;
				width: 100%;
				padding: 0 5vw;
				box-sizing: border-box;
				.top {
					position: relative;
					display: flex;
					p {
						color: $black;
						font-size: 14px;
						font-weight: 400;
						font-family: 'Suisse Int';
						margin: 0;
						line-height: 16px;
						@include mobile {
							font-size: 12px;
							margin-bottom: 10px;
						}
					}
					span {
						color: $black;
						font-size: 14px;
						font-weight: 400;
						font-family: 'Suisse Int';
						margin: 0;
						line-height: 16px;
						text-transform: uppercase;
						margin-left: 10px;
						text-align: right;
						@include mobile {
							font-size: 12px;
							margin-bottom: 10px;
							margin-left: 0;
						}
					}
					a {
						color: $black;
						font-size: 12px;
						font-weight: 500;
						font-family: 'Suisse Int';
						margin: 0;
						line-height: 16px;
						text-decoration: none;
						text-transform: uppercase;
						margin-left: 10px;
						@include mobile {
							font-size: 12px;
							margin-bottom: 10px;
							margin-left: 0;
						}
					}
					@include mobile {
						flex-direction: column;
						text-align: center;
					}
				}
			}
			.bottom {
				position: relative;
				display: flex;
				padding: 1vh 0;
				justify-content: space-between;
				span {
					color: $black;
					font-size: 12px;
					font-weight: 400;
					font-family: 'Suisse Int';
					margin: 0;
					line-height: 16px;
					text-transform: uppercase;
					margin-left: 0;
					display: flex;
					text-align: center;
					justify-content: flex-end;
					flex-grow: 1;
					flex-basis: 0;
				}
				a {
					flex-grow: 1;
					flex-basis: 0;
					&.prvc {
						color: $black;
						font-size: 12px;
						font-weight: 500;
						font-family: 'Suisse Int';
						margin: 0;
						line-height: 16px;
						text-decoration: none;
						text-transform: uppercase;
					}
					&.logo {
						position: relative;
						display: flex;
						text-align: center;
						justify-content: center;
						svg {
							height: 16px;
							fill: $black;
							width: auto;
							display: block;
						}
					}
				}
			}
		}
	}
</style>