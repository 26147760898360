<template>
	<div class="form--cover">
		<div class="spinner">
			<svg class="spinner--circle" width="5vw" height="5vw" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
				<circle class="path" fill="none" stroke-width="1" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
			</svg>
			<LogoSVG class="spinner--logo" />
		</div>
	</div>
</template>

<script>
import LogoSVG from '@/components/elements/LogoSVG';
import gsap from 'gsap';
export default {
	props: {
		isVisible: {
			type: Boolean
		}
	},
	components: {
		LogoSVG
	},
	methods: {
		openLoader() {
			if(!this.$formLoaderAnimation){
				gsap.to(document.querySelector('.form--cover'), {duration: 0, autoAlpha: 1})
			}else{
				gsap.to(document.querySelector('.form--cover'), {duration: 1, autoAlpha: 1, ease: 'power2.out'})
			}
			
		},
		closeLoader() {
			if(!this.$formLoaderAnimation){
				gsap.to(document.querySelector('.form--cover'), {duration: 0, autoAlpha: 0})
			}else{
				gsap.to(document.querySelector('.form--cover'), {duration: 1, autoAlpha: 0, ease: 'power2.out'})
			}
		}
	},
	watch: {
		isVisible: {
      deep: true,
      handler: function(val){
        if(val){					
          this.openLoader();
        }else{
					this.closeLoader();
        }
      }
    }
	},
	mounted() {
		if(this.isVisible){
			this.openLoader()
		}else{
			this.closeLoader()
		}
	}
}
</script>

<style lang="scss">
$offset: 187;
$duration: 1.4s;
@keyframes logoRot {
  0% { transform: rotateY(0deg); }
  100% { transform: rotateY(360deg); }
}
@keyframes rotator {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(270deg); }
}
@keyframes dash {
 0% { stroke-dashoffset: $offset; }
 50% {
   stroke-dashoffset: $offset/4;
   transform:rotate(135deg);
 }
 100% {
   stroke-dashoffset: $offset;
   transform:rotate(450deg);
 }
}
.form--cover {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	z-index: 201;
	background-color: rgba($white, 0.9);
	visibility: hidden;
	opacity: 0;
	.spinner {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		@include mobile {
			width: 40vw;
		}
		.spinner--circle {
			animation: rotator $duration linear infinite;
			.path {
				stroke-dasharray: $offset;
				stroke-dashoffset: 0;
				transform-origin: center;
				stroke: $red;
				opacity: 0.7;
				animation:
					dash $duration ease-in-out infinite, 
					colors ($duration*4) ease-in-out infinite;
			}
			@include mobile {
				width: 40vw;
				heighT: 40vw;
			}
		}
		.spinner--logo {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			fill: $red;
			opacity: 0.7;
			//animation: logoRot $duration linear infinite;
		}
	}
}
</style>